<template>
    <div class="b-1 pb-2 bg-2 mb-4 news-card-min-h mu-p" @click="$router.push('/media/' + id + '/details/' + mediaType + '/')">
        <div class="card-img-min-h"
             :style="{'background-image':'url('+ img +')', 'background-size':'cover', 'background-repeat':'no-repeat', 'background-position':'0% 0%'}">
        </div>
        <div class="fl-y fl-j-sb card-content">
            <div class="p-3 wrapped-text heading-and-text-max-h of-a thin-scrollbar">
                <h6 class="font-weight-bold font-semi-bold fs-2">{{ heading }}</h6>
                <p class="fs--1 mt-3 font-inter-regular text-3 align-justify pr-2"> {{ content }} </p>
            </div>
            <p class="fs--1 mt-3 font-inter-regular px-3 text-3 align-justify pr-2 text-right" v-if="hour"> {{ hour }} </p>
        </div>
    </div>
</template>
<script>

export default {
    name: 'NewsCard',
    components: {},
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        mediaType: {
            type: String,
            default: 'news'
        },
        heading: {
            type: String,
            default: ''
        },
        route: {
            type: String,
            default: ''
        },
        hour: {
            type: String,
            default: ''
        },
        categ: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: String
        },
        content: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    }
};
</script>
<style lang="scss" scoped>
    .card-img-min-h {
        position: relative;
        height: 13rem;

        img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .news-card-min-h {
        min-height: 28.5rem !important;
        height: 28.5rem !important;
    }

    .border-left {
        border-left: 1px solid var(--text-2);
    }

    .wrapped-text {
        word-wrap: break-word;
    }
    .card-content{
        /*for aligning the time (hours ago text)*/
        height: 55% !important;
    }
    .heading-and-text-max-h{
        max-height: 12.5rem !important;
    }
</style>
