<template>
  <div class="fl-x">
    <div class="fl-x fs-lg-1" v-for="(item,i) in path" :key="i">
      <span class="mr-lg-2 text-decoration-none">
        {{ item.name }}
      </span>
      <div class="mr-lg-2">
        <img src="../../assets/mobile/home/icons/chevron-right-primary-24.png" alt="">
      </div>
    </div>
  </div>

</template>

<script>
export default {
    name: 'BaseBreadcrumb',
    props: {
        path: {
            type: Array,
            default: null
        }
    }
};
</script>

<style scoped>

</style>
