<template>
    <div>
        <section v-if="dataLoading" class="about py-lg-9">
            <div class="text-center pt-7 pb-7">
                <loading-animation class="text-primary text-5x"/>
            </div>
        </section>
        <div v-if="!dataLoading && details">
            <div class="container py-6">
                <base-breadcrumb v-if="mediaType==='news'" :path="[{name :'News'},
                             {name :details.media.title}]"/>
                <base-breadcrumb v-else :path="[{name :'Blogs'}, {name :details.media.title}]"/>
                <div class="ml-lg-5 mr-lg-8">
                    <div class="row">
                        <div class="col-12">
                            <img v-if="details.media.image" :src="details.media.image" alt="" class="article-img">
                            <div class="ml-3 mt-3">
                                <div class="mb-2">
                                <span class="text-secondary font-inter-bold text-uppercase fs--1 mr-2">
                                    {{ details.media.category }}</span>
                                </div>
                                <h6 @click="viewLink" class="font-weight-bold font-semi-bold fs-lg-9"
                                    :class="mediaType === 'news' ? 'cursor-pointer' : ''">
                                    {{ details.media.title }}
                                </h6>
                                <p class="fs--1 mt-3 font-inter-medium align-justify wrapped-text">
                                    {{ details.media.full_description }} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-4">
                <div class="container" v-if="details.related_media.length!==0">
                    <h3 class="fs-5 font-inter-medium mb-4">
                        {{ mediaType === 'news' ? 'Related News' : 'Related Blogs' }}
                    </h3>
                    <div class="row">
                        <div v-for="(item,i) in details.related_media" :key="i" class="col-12 col-lg-4 col-md-6">
                            <news-card
                                :id="item.id.toString()"
                                :hour="item.created_at"
                                :heading="item.title"
                                :content="item.full_description.substring(0, 140)+' ...'"
                                :img="item.image"
                                :categ="item.category"
                                :media-type="mediaType"
                            ></news-card>
                        </div>
                    </div>
                </div>
            </div>
            <sub-footer></sub-footer>
        </div>
    </div>
</template>

<script>
import BaseBreadcrumb from '../../components/ui/BaseBreadcrumb';
import NewsCard from '../../components/home/NewsCard';
import SubFooter from '@components/home/PremiumMembershipSection';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'MediaArticlePage',
    components: { SubFooter, NewsCard, BaseBreadcrumb },
    props: {
        categ: {
            type: String,
            default: 'All'
        },
        heading: {
            type: String,
            default: 'Heading'
        },
        content: {
            type: String,
            default: 'Content'
        }
    },
    data () {
        return {
            details: '',
            id: this.$route.params.id,
            mediaType: this.$route.params.media_type,
            dataLoading: false
        };
    },
    watch: {
        '$route.params.media_type': {
            handler () {
                this.mediaType = this.$route.params.media_type;
            }
        },
        '$route.params.id': {
            handler () {
                this.$router.go(0);
            }
        }
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        viewLink () {
            if (this.details.media.news_link) {
                window.open(this.details.media.news_link);
            }
        },
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.form(urls.website.mediaDetails, {
                id: this.id,
                media_type: this.mediaType
            });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped lang="scss">
.article-img {
    width: 100%;;
    position: relative;
    @media (min-width: 992px) {
        height: 60vh !important;
    }
}

.wrapped-text {
    word-wrap: break-word;
}
</style>
